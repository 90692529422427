@media only screen and (min-width: 600px) {
    #compecrocks {
        font-size: 5vw;
    }
    #info{
        font-size: 1.2vw;
    
    }
}
@media only  screen and (max-width: 600px)  {
    #compecrocks {
        font-size: 12vw;
    }
    #info{
        font-size: 5vw;
    
    }
}



#bg{
background-image: url("https://images.unsplash.com/photo-1549057446-9f5c6ac91a04?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1791&q=80")
;
margin-top: 12px;
background-size: cover;
color: white;
text-shadow: 3px 3px 5px #000000;
}